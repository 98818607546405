// Import the functions you need from the SDKs you need

// import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB-brDCoigVNad2Nj4xnsQjzYbvi1J9g6k",
  authDomain: "inventory-project-ee.firebaseapp.com",
  projectId: "inventory-project-ee",
  storageBucket: "inventory-project-ee.appspot.com",
  messagingSenderId: "1007729857080",
  appId: "1:1007729857080:web:b2c20fd00892c1eea98ccd"
};

// Initialize Firebase
// const app = initializeApp(firebaseConfig);

export default firebaseConfig;